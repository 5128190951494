import {
  WppButton,
  WppIconMore,
  WppListItem,
  WppMenuContext,
  WppTypography,
  WppTag,
  WppIconEdit,
  WppIconTrash,
  WppTooltip,
  WppTabs,
  WppTab,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Link, matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import { useDeleteWorkflowTemplateApi } from 'api/canvas/mutation/useDeleteWorkflowTemplateApi'
import { useUpdateWorkflowTemplateApi } from 'api/templates/queries/usePatchWorkflowTemplateApi'
import { showConfirmModal } from 'components/common/confirmModal/ConfirmModal'
import { showDeleteModal } from 'components/common/deleteModal/DeleteModal'
import { Flex } from 'components/common/flex/Flex'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useTemplate } from 'hooks/useTemplate'
import { useToast } from 'hooks/useToast'
import { BackToTemplatesButton } from 'pages/components/backToTemplatesButton/BackToTemplatesButton'
import { useOpenTemplatePreviewModals } from 'pages/template/hooks/useOpenTemplatePreviewModals'
import styles from 'pages/template/Template.module.scss'
import { showEditTemplateModal } from 'pages/templates/components/editTemplateModal/EditTemplateModal'
import { useTemplateAnalytics } from 'pages/templates/hooks/useTemplateAnalytics'
import { queryClient } from 'providers/osQueryClient/utils'
import { AppPermissions } from 'types/permissions/permissions'
import { ProcessType } from 'types/projects/projects'
import { is409Error } from 'utils/error'
import { templateTabs } from 'utils/project'
import { routesManager } from 'utils/routesManager'

export const Template = () => {
  const { template, isOwner, linearData, fluidData } = useTemplate()
  const location = useLocation()
  const { trackTemplateDelete, trackViewWorkflowTemplate } = useTemplateAnalytics()
  useOpenTemplatePreviewModals(template)

  const { showToast } = useToast()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { isPermitted } = useIsPermitted()

  const { mutateAsync: updateTemplate } = useUpdateWorkflowTemplateApi()
  const { mutateAsync: handleDeleteTemplate } = useDeleteWorkflowTemplateApi()

  const currentTab = matchPath(`${routesManager.template.root.pattern}/:tab`, location.pathname)

  const isGlobalManage = isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const isOwnerOrGlobalManage = isOwner || isGlobalManage

  useEffectOnce(() => {
    trackViewWorkflowTemplate({
      processType: template.processType,
      hasTags: !!template.tags?.length || false,
      numberOfPhases: template.processType === ProcessType.LINEAR ? template.phasesStats.length : 0,
      numberOfApps: template.phasesStats.reduce((acc, phase) => acc + phase.appsCount, 0),
    })
  })

  const handleDelete = async (id: string) => {
    try {
      await handleDeleteTemplate({ id })

      trackTemplateDelete()
      await queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES_INFINITE])
      await queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES])

      navigate(routesManager.templates.root.getURL())
    } catch (e) {
      showToast({
        type: 'error',
        message: t('common.generic_error'),
      })
      console.error(e)
    }
  }

  const handlePublish = async () => {
    try {
      await updateTemplate({
        id: template.id,
        isPublished: true,
      })

      queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES])
      queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES_BY_ID])
      queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES_INFINITE])

      showToast({
        type: 'success',
        message: t('modals.save_project_as_template.toast_success_message'),
      })
    } catch (e) {
      const is409 = is409Error(e)
      let message = t('modals.create_project.toast_error_common')

      if (is409) message = t('modals.edit_template_details.toast_error_duplicate')

      showToast({
        type: 'error',
        message,
      })
      console.error(e)
    }
  }

  const publishTemplate = () => {
    showConfirmModal({
      title: t('modals.edit_template_details.save_confirmation_title'),
      btnSubmitText: t('common.btn_publish')!,
      handleSubmit: handlePublish,
      confirmMessage: t('modals.edit_template_details.save_confirmation_message')!,
    })
  }

  const isLinear = template.processType === ProcessType.LINEAR
  const hasItems = isLinear ? linearData.phaseOrder.length > 0 : (fluidData?.items?.length || 0) > 0

  return (
    <>
      <Flex direction="column" className={clsx(styles.container)} gap={8}>
        <BackToTemplatesButton />
        <Flex direction="column" gap={8} className={styles.headContainer}>
          <Flex justify="between">
            <WppTypography type="3xl-heading" data-testid="project-name">
              {template.name}
            </WppTypography>

            <Flex justify="between" gap={12}>
              {isOwnerOrGlobalManage && (
                <Flex align="center" gap={12}>
                  <WppMenuContext>
                    <WppButton slot="trigger-element" variant="secondary" data-testid="template-shortcuts">
                      <WppIconMore slot="icon-start" direction="horizontal" color="var(--wpp-primary-color-500)" />
                    </WppButton>
                    <WppListItem
                      data-testid="template-edit-details"
                      onWppChangeListItem={() => showEditTemplateModal({ template })}
                    >
                      <WppIconEdit slot="left" />
                      <span slot="label">{t('template.edit_details')}</span>
                    </WppListItem>

                    <WppListItem
                      data-testid="template-delete"
                      onWppChangeListItem={() =>
                        showDeleteModal({
                          title: t('template.delete_message_title'),
                          subTitle: t(
                            template.isPublished
                              ? 'template.delete_message_subtitle_published'
                              : 'template.delete_message_subtitle',
                          ),
                          onDelete: () => handleDelete(template.id),
                        })
                      }
                    >
                      <WppIconTrash slot="left" />
                      <WppTypography slot="label" type="s-body">
                        {t('template.delete')}
                      </WppTypography>
                    </WppListItem>
                  </WppMenuContext>
                  {!template.isPublished && (
                    <WppTooltip
                      text={t('template.publish_empty')!}
                      config={{
                        trigger: hasItems ? 'manual' : 'mouseenter',
                        hideOnClick: false,
                      }}
                    >
                      <div>
                        <WppButton
                          size="m"
                          onClick={() => publishTemplate()}
                          data-testid="publish-template"
                          disabled={!hasItems}
                        >
                          {t('template.btn_publish')}
                        </WppButton>
                      </div>
                    </WppTooltip>
                  )}
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex>
            <WppTag
              variant={template.isPublished ? 'positive' : 'warning'}
              data-testid="template-status"
              label={template.isPublished ? t('template.status_published') : t('template.status_draft')}
            />
          </Flex>
          <Flex gap={24}>
            <WppTabs
              value={currentTab?.params.tab || routesManager.project.overview.shortPattern}
              size="s"
              className={styles.nav}
            >
              {templateTabs.map(({ id, value }) => (
                <Link to={id} key={id} tabIndex={-1}>
                  <WppTab value={id} size="s">
                    {value}
                  </WppTab>
                </Link>
              ))}
            </WppTabs>
          </Flex>
        </Flex>

        <Outlet />
      </Flex>
    </>
  )
}
